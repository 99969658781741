import moment from 'moment';
import { Modal } from 'antd';
import { toGlobalId, fromGlobalId } from 'graphql-relay';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { error } = Modal;

export const convertToDateStr = date => {
  const dateObj = moment(date);
  return dateObj.format('D/M/YYYY');
};

export const convertToTimeStr = time => {
  const timeObj = moment(new Date('2020-01-01 ' + time));
  return timeObj.format('LT');
};

export const convertToArTimeStr = time => {
  const timeObj = moment(new Date('2020-01-01 ' + time));
  return timeObj
    .format('LT')
    .replaceAll('AM', 'صباحاً')
    .replaceAll('PM', 'مساءً');
};

export const isValidValue = value => {
  return ![undefined, null, ''].includes(value);
};

export const showError = (title, content) => {
  error({
    title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    centered: true,
  });
};

// Function to convert a number to a global identifier
export const numberToGlobalId = (type, number) => {
  // Use the toGlobalId function to create a global identifier
  return toGlobalId(type, number.toString());
};

// Function to convert a global identifier back to a number
export const globalIdToNumber = globalId => {
  // Use the fromGlobalId function to parse the global identifier
  const { id } = fromGlobalId(globalId);
  // Assuming that the ID stored in the global identifier is a number
  // You may need to add validation or handle different types accordingly
  return parseInt(id, 10);
};
