import React, { useEffect } from 'react';

import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from 'antd';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import dayjs from 'dayjs';

import { useQuery } from '@apollo/client';
import { GET_LOCATIONS_LIST, GET_TAGS_LIST } from '../../graphql/queries';

import moment from 'moment';

const { Option } = Select;

const GeneralDetails = ({ onChange, data, setData, initiative }) => {
  const { loading: tagsListLoading, data: tagsList } = useQuery(GET_TAGS_LIST);

  const { loading: locationsListLoading, data: locationsList } = useQuery(
    GET_LOCATIONS_LIST,
    {
      variables: { isMainLocation: true, isActive: true },
    },
  );

  useEffect(() => {
    setData({
      english_name: initiative.englishName,
      arabic_name: initiative.arabicName,
      english_description: initiative.englishDescription,
      arabic_description: initiative.arabicDescription,
      starting_date: initiative.startingDate,
      ending_date: initiative.endingDate,
      starting_time: initiative.startingTime,
      ending_time: initiative.endingTime,
      location: initiative.location.pk,
      tag: initiative.tag.pk,
      image: initiative.image,
      contract_en: initiative.contractEn,
      contract_ar: initiative.contractAr,
      registration_closed: initiative.registrationClosed,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateEndingDate = (_, value) => {
    if (!data.ending_date || !data.starting_date) return Promise.resolve();

    if (moment(data.ending_date).isAfter(moment(data.starting_date)))
      return Promise.resolve();

    return Promise.reject(
      new Error('Ending date can not before the starting date.'),
    );
  };

  return (
    <>
      <p className="font-weight-bold text-error">
        - Maximum allowed size for an image: 2 MB.
      </p>

      <Row gutter={[8, 8]} justify="center">
        <Col md={12} xs={24}>
          <Form.Item
            name="english_name"
            label="Initiative Name in English"
            rules={[{ required: true }]}
            initialValue={initiative?.englishName}
          >
            <Input
              value={data.english_name}
              onChange={onChange}
              name="english_name"
            />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            name="arabic_name"
            label="Initiative Name in Arabic"
            rules={[{ required: true }]}
            initialValue={initiative?.arabicName}
          >
            <Input
              name="arabic_name"
              value={data.arabic_name}
              onChange={onChange}
              className="arabic-field"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <Form.Item
            name="english_description"
            label="Initiative Description in English"
            rules={[{ required: true }]}
            initialValue={initiative?.englishDescription}
          >
            <ReactQuill
              className="react-quill-en"
              value={data.english_description}
              onChange={value =>
                setData({
                  ...data,
                  english_description: value,
                })
              }
            />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item
            name="arabic_description"
            label="Initiative Description in Arabic"
            rules={[{ required: true }]}
            initialValue={initiative?.arabicDescription}
          >
            <ReactQuill
              className="react-quill-ar"
              value={data.arabic_description}
              onChange={value =>
                setData({
                  ...data,
                  arabic_description: value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col md={6} xs={24}>
          <Form.Item
            name="starting_date"
            label="Starting Date"
            rules={[{ required: true }]}
            initialValue={dayjs(initiative?.startingDate, 'YYYY-MM-DD')}
          >
            <DatePicker
              name="starting_date"
              value={data.starting_date}
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              onChange={(value, stringValue) =>
                setData({
                  ...data,
                  starting_date: stringValue,
                })
              }
            />
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name="ending_date"
            label="Ending Date"
            rules={[{ required: true }, { validator: validateEndingDate }]}
            initialValue={dayjs(initiative?.endingDate, 'YYYY-MM-DD')}
          >
            <DatePicker
              name="ending_date"
              value={data.ending_date}
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              onChange={(value, stringValue) =>
                setData({
                  ...data,
                  ending_date: stringValue,
                })
              }
            />
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name="starting_time"
            label="Starting Time"
            rules={[{ required: true }]}
            initialValue={dayjs(initiative?.startingTime, 'HH:mm')}
          >
            <TimePicker
              name="starting_time"
              value={data.starting_time}
              format="HH:mm"
              use12Hours
              style={{ width: '100%' }}
              onChange={(value, stringValue) =>
                setData({
                  ...data,
                  starting_time: stringValue,
                })
              }
            />
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name="ending_time"
            label="Ending Time"
            rules={[{ required: true }]}
            initialValue={dayjs(initiative?.endingTime, 'HH:mm')}
          >
            <TimePicker
              name="ending_time"
              value={data.ending_time}
              format="HH:mm"
              use12Hours
              style={{ width: '100%' }}
              onChange={(value, stringValue) =>
                setData({
                  ...data,
                  ending_time: stringValue,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col md={6} xs={24}>
          <Form.Item
            name="age_from"
            label="Age From"
            rules={[{ required: true }]}
            initialValue={initiative?.ageFrom}
          >
            <Input
              name="age_from"
              type="number"
              min="0"
              step="1"
              onChange={onChange}
            />
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name="age_to"
            label="Age To"
            rules={[{ required: true }]}
            initialValue={initiative?.ageTo}
          >
            <Input
              name="age_to"
              type="number"
              min="0"
              step="1"
              onChange={onChange}
            />
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true }]}
            initialValue={initiative?.location?.pk}
          >
            <Select
              name="location"
              value={
                !locationsListLoading && data.location_id
                  ? data.location_id
                  : ''
              }
              onChange={value => setData({ ...data, location: value })}
            >
              <Option key="" value="">
                -----
              </Option>
              ,
              {!locationsListLoading
                ? locationsList.locations.edges.map(location => (
                    <Option key={location.node.pk} value={location.node.pk}>
                      {location.node.nameEn}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>

        <Col md={6} xs={24}>
          <Form.Item
            name="tag"
            label="Tag"
            rules={[{ required: true }]}
            initialValue={initiative?.tag?.pk}
          >
            <Select
              name="tag"
              value={!tagsListLoading && data.tag_id ? data.tag_id : ''}
              onChange={value => setData({ ...data, tag: value })}
            >
              <Option key="" value="">
                -----
              </Option>
              ,
              {!tagsListLoading
                ? tagsList?.tags.edges.map(obj => (
                    <Option key={obj.node.pk} value={obj.node.pk}>
                      {obj.node.title}
                    </Option>
                  ))
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col md={8} xs={24}>
          <Form.Item name="image" label="Image (.jpg, .jpeg, .png)">
            <input
              type="file"
              className="form-control"
              name="image"
              accept=".jpg, .jpeg, .png"
              onChange={onChange}
            />
          </Form.Item>

          <small
            className="w-100"
            style={{
              marginLeft: '175px',
            }}
          >
            click&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={initiative?.image}
            >
              here
            </a>
            &nbsp;to view the current image.
          </small>
        </Col>

        <Col md={8} xs={24}>
          <Form.Item name="contract_en" label="Contract EN (.pdf)">
            <input
              type="file"
              className="form-control"
              name="contract_en"
              accept=".pdf"
              onChange={onChange}
            />
          </Form.Item>

          {initiative?.contractEn && (
            <small
              className="w-100"
              style={{
                marginLeft: '175px',
              }}
            >
              click&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={initiative?.contractEn}
              >
                here
              </a>
              &nbsp;to view the current file.
            </small>
          )}
        </Col>

        <Col md={8} xs={24}>
          <Form.Item name="contract_ar" label="Contract AR (.pdf)">
            <input
              type="file"
              className="form-control"
              name="contract_ar"
              accept=".pdf"
              onChange={onChange}
            />
          </Form.Item>

          {initiative?.contractAr && (
            <small
              className="w-100"
              style={{
                marginLeft: '175px',
              }}
            >
              click&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={initiative?.contractAr}
              >
                here
              </a>
              &nbsp;to view the current file.
            </small>
          )}
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col md={8} xs={24}>
          <Form.Item
            name="registration_closed"
            initialValue={initiative?.registrationClosed}
          >
            <Checkbox
              checked={data.registration_closed}
              onChange={event =>
                setData({
                  ...data,
                  registration_closed: event.target.checked,
                })
              }
            >
              Close Registration
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default GeneralDetails;
