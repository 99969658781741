/**
 *
 * Asynchronously loads the component for Levels
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Levels = lazyLoad(
  () => import('./index'),
  module => module.Levels,
);
