/**
 *
 * Profile
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Card, Col, Form, Input, Row } from 'antd';

import { useAuth } from '../../hooks/useAuth';
import { FetchProfile } from '../../redux/actions/auth';

import { Loading } from '../../components/Loading';

import ProfilePageStyles from './styles';

export const Profile = memo(() => {
  const { t } = useTranslation();

  const [staffObj, setStaffObj] = useState(null);
  const [staffObjLoading, setStaffObjLoading] = useState(true);

  const dispatch = useDispatch();

  const { user } = useAuth();

  // fetch usher profile info.
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(FetchProfile(user.userID));
      setStaffObj(response);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set loading to false after fetching the profile info.
  useEffect(() => {
    if (staffObj) setStaffObjLoading(false);
  }, [staffObj]);

  if (staffObjLoading) return <Loading />;

  return (
    <ProfilePageStyles>
      <Form>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card title={t('profilePage.personalPicture')} className="mb-3">
              <Row justify="center" className="mb-3">
                <img src={staffObj.profile_image} alt=""></img>
              </Row>

              <Row justify="center">
                <p>
                  <b>{t('profilePage.loyacID')}: </b>
                  {staffObj.user.id}
                </p>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card title={t('profilePage.generalDetails')} className="mb-3">
              <Row gutter={[8, 8]}>
                <Col lg={12} xs={24}>
                  <Form.Item
                    name="english_full_name"
                    label={t('profilePage.englishFullName')}
                    initialValue={staffObj.name}
                  >
                    <Input className="english-field" readOnly />
                  </Form.Item>
                </Col>

                <Col lg={12} xs={24}>
                  <Form.Item
                    name="arabic_full_name"
                    label={t('profilePage.arabicFullName')}
                    initialValue={staffObj.arabic_name}
                  >
                    <Input className="arabic-field" readOnly />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col lg={8} xs={24}>
                  <Form.Item
                    name="email"
                    label={t('profilePage.email')}
                    initialValue={staffObj.user.email}
                  >
                    <Input className="english-field" readOnly />
                  </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                  <Form.Item
                    name="department"
                    label={t('profilePage.department')}
                    initialValue={staffObj.department}
                  >
                    <Input className="english-field" readOnly />
                  </Form.Item>
                </Col>

                <Col lg={8} xs={24}>
                  <Form.Item
                    name="job_title"
                    label={t('profilePage.jobTitle')}
                    initialValue={staffObj.job_title}
                  >
                    <Input className="english-field" readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </ProfilePageStyles>
  );
});
