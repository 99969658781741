import styled from 'styled-components';

const InitiativeEditFormStyles = styled.div`
  overflow-x: hidden;

  .ant-card {
    border-radius: 0;
  }

  button,
  span {
    display: inline-flex;
    align-items: center;
  }
`;

export default InitiativeEditFormStyles;
