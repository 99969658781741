import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import {
  Row,
  Col,
  Typography,
  Button,
  Table,
  Badge,
  Popover,
  Tag,
  Flex,
} from 'antd';
import {
  FilterFilled,
  CaretDownFilled,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { exportToExcel, cellBadge } from '../../helpers/attendanceFunctions';

import { Loading } from '../../components/Loading';

const { Title } = Typography;

const AttendanceTable = ({
  attendanceDataLoading,
  attendanceData,
  initiative,
  initiativeLabel,
  setInitiative,
  searchParams,
  setSearchParams,
  showDrawer,
}) => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    if (attendanceData) {
      setDataSource(attendanceData.rows);
      const dates = attendanceData.columns;

      const tableColumns = [
        {
          title: 'Usher ID',
          key: 'usher-id',
          align: 'center',
          width: '10%',
          fixed: 'left',
          render: (text, record) => {
            return record.usherID;
          },
        },
        {
          title: 'Full Name',
          key: 'usher-name',
          align: 'center',
          width: '10%',
          fixed: 'left',
          render: (text, record) => {
            return (
              <Link
                to={`/ushers/${record.usherID}/profile/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.usherName}
              </Link>
            );
          },
        },
        ...dates.map((date, index) => ({
          title: date,
          dataIndex: date,
          key: date,
          align: 'center',
          width: '5%',
          render: (text, record) => {
            const matchingRecord = record.attendanceRecords[index];
            return (
              <Popover
                content={
                  <>
                    Attendance Record:
                    <ul>
                      <li className="m-0">
                        Check In Time:&nbsp;
                        {matchingRecord[date]?.checkInTime || 'N/A'}
                      </li>

                      <li>
                        Check Out Time:&nbsp;
                        {matchingRecord[date]?.checkOutTime || 'N/A'}
                      </li>
                    </ul>
                    Assigned Shift:
                    <ul>
                      <li>
                        Shift Starting Time:&nbsp;
                        {record.assignedShift.startingTime}
                      </li>

                      <li>
                        Shift Ending Time:&nbsp;
                        {record.assignedShift.endingTime}
                      </li>
                    </ul>
                  </>
                }
              >
                {cellBadge(matchingRecord[date], record.assignedShift)}
              </Popover>
            );
          },
        })),
        {
          title: 'Shifts',
          key: 'shifts',
          align: 'center',
          width: '5%',
          fixed: 'right',
          render: (text, record) => {
            return 5;
          },
        },
        {
          title: 'Hours',
          key: 'hours',
          align: 'center',
          width: '5%',
          fixed: 'right',
          render: (text, record) => {
            return 5;
          },
        },
        {
          title: 'Reward',
          key: 'reward',
          align: 'center',
          width: '5%',
          fixed: 'right',
          render: (text, record) => {
            return 5;
          },
        },
        {
          title: 'Deduction',
          key: 'deduction',
          align: 'center',
          width: '5%',
          fixed: 'right',
          render: (text, record) => {
            return 5;
          },
        },
        {
          title: 'Total',
          key: 'total',
          align: 'center',
          width: '5%',
          fixed: 'right',
          render: (text, record) => {
            return 5;
          },
        },
      ];

      setColumns(tableColumns);
    }
  }, [attendanceData]);

  if (attendanceDataLoading) return <Loading />;

  return (
    <>
      <Row justify="space-between" align="middle" className="mb-3">
        <Col>
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <Title level={3} className="mb-0">
              Attendance History List
            </Title>
          </div>
        </Col>

        <Col>
          <Button
            type="primary"
            style={{ height: '40px' }}
            className="me-3"
            disabled={!initiative}
            onClick={() => exportToExcel(dataSource, columns)}
          >
            <CaretDownFilled style={{ fontSize: '18px' }} />
          </Button>

          <Button
            type="primary"
            style={{ height: '40px' }}
            onClick={showDrawer}
          >
            <FilterFilled style={{ fontSize: '18px' }} />
          </Button>
        </Col>
      </Row>

      <Flex gap="4px 12px" wrap="wrap" className="mb-4">
        <Badge color="red" text="No attendance record" />
        <Badge color="orange" text="Did not check in or out" />
        <Badge color="green" text="Normal Hours" />
        <Badge color="yellow" text="Missing Hours" />
        <Badge color="geekblue" text="Extra Hours" />
      </Flex>

      <Flex gap="4px 12px" wrap="wrap" className="mb-4">
        {initiative && (
          <Tag
            color="cyan"
            closeIcon={<CloseCircleOutlined />}
            onClose={() => setInitiative(null)}
          >
            initiative: {initiativeLabel}
          </Tag>
        )}

        {searchParams.month && (
          <Tag
            color="volcano"
            closeIcon={<CloseCircleOutlined />}
            onClose={() => setSearchParams({ ...searchParams, month: '' })}
          >
            month: {moment(searchParams.month, 'M').format('MMMM')}
          </Tag>
        )}

        {searchParams.year && (
          <Tag
            color="purple"
            closeIcon={<CloseCircleOutlined />}
            onClose={() => setSearchParams({ ...searchParams, year: '' })}
          >
            year: {searchParams.year}
          </Tag>
        )}
      </Flex>

      {!initiative ? (
        <Table
          dataSource={null}
          locale={{
            emptyText:
              'kindly select an initiative to be able to see the related attendance data.',
          }}
          columns={[]}
        />
      ) : (
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
          scroll={{ x: true }}
        />
      )}
    </>
  );
};

export default AttendanceTable;
