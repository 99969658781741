/**
 *
 * Tags
 *
 */
import React, { memo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Card,
  Form,
  Button,
  Radio,
  Space,
  Input,
  Row,
  Col,
  DatePicker,
  TimePicker,
  InputNumber,
  Divider,
} from 'antd';

import NewSlotFormStyles from './NewSlotFormStyles';

import { NewTimeSlot } from '../../redux/actions/initiatives';

export const NewSlotForm = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { initiativeID, initiativeName, steps } = state;

  const [errorMessage, setErrorMessage] = useState(null);
  const [inputFields, setInputFields] = useState([
    {
      date: '',
      starting_time: '',
      ending_time: '',
      capacity: '',
      platform: '',
      location: '',
      location_details: '',
    },
  ]);

  const onFinish = async () => {
    const response = await dispatch(NewTimeSlot(inputFields));
    if (typeof response === 'string' && response.includes('Error'))
      setErrorMessage(response);
    else {
      setErrorMessage(null);
      navigate(`/initiatives/${initiativeID}/slots`);
    }
  };

  const addFields = () => {
    const newField = {
      date: '',
      starting_time: '',
      ending_time: '',
      capacity: '',
      platform: '',
      location: '',
      location_details: '',
    };
    setInputFields([...inputFields, newField]);
  };

  const removeFields = index => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleFormChange = (index, eventName, eventValue) => {
    let data = [...inputFields];
    data[index][eventName] = eventValue
      ? eventName === 'starting_time' || eventName === 'ending_time'
        ? eventValue.format('HH:mm').toString()
        : eventName === 'date'
        ? eventValue.format('YYYY-MM-DD').toString()
        : eventValue
      : eventValue;
    setInputFields(data);
  };

  return (
    <NewSlotFormStyles>
      <Card className="new-slot-form-card">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h3>
            New Time Slots Form
            {initiativeName && <span> | {initiativeName}</span>}
          </h3>
        </div>

        <Form
          onFinish={onFinish}
          style={{
            paddingTop: '24px',
          }}
        >
          {errorMessage && <p className="text-error">{errorMessage}</p>}

          <Form.List name="initiativeTimeSlots" initialValue={inputFields}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <Row gutter={[8, 8]}>
                        <Col md={12} xs={24}>
                          <Form.Item
                            name={[index, 'initiative_step']}
                            label="Step"
                            rules={[
                              {
                                required: true,
                                message: "'initiative_step' is required",
                              },
                            ]}
                          >
                            <Radio.Group
                              onChange={event => {
                                handleFormChange(
                                  index,
                                  'initiative_step',
                                  event.target.value,
                                );
                              }}
                            >
                              <Space direction="vertical">
                                {steps
                                  .filter(obj => obj.step.shouldBook)
                                  .map(obj => (
                                    <Radio key={obj.id} value={obj.id}>
                                      {obj.step.title}
                                    </Radio>
                                  ))}
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                          <Form.Item
                            name={[index, 'date']}
                            label="Date"
                            rules={[
                              {
                                required: true,
                                message: "'date' is required",
                              },
                            ]}
                          >
                            <DatePicker
                              name="date"
                              format="YYYY-MM-DD"
                              style={{ width: '100%' }}
                              onChange={value =>
                                handleFormChange(index, 'date', value)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[8, 8]}>
                        <Col md={12} xs={24}>
                          <Form.Item
                            name={[index, 'starting_time']}
                            label="Starting Time"
                            rules={[
                              {
                                required: true,
                                message: "'starting_time' is required",
                              },
                            ]}
                          >
                            <TimePicker
                              name="starting_time"
                              format="HH:mm"
                              use12Hours
                              style={{ width: '100%' }}
                              onChange={value =>
                                handleFormChange(index, 'starting_time', value)
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                          <Form.Item
                            name={[index, 'ending_time']}
                            label="Ending Time"
                            rules={[
                              {
                                required: true,
                                message: "'ending_time' is required",
                              },
                            ]}
                          >
                            <TimePicker
                              name="ending_time"
                              format="HH:mm"
                              use12Hours
                              style={{ width: '100%' }}
                              onChange={value =>
                                handleFormChange(index, 'ending_time', value)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[8, 8]}>
                        <Col md={12} xs={24}>
                          <Form.Item
                            name={[index, 'capacity']}
                            label="Capacity"
                            rules={[
                              {
                                required: true,
                                message: "'capacity' is required",
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              name="capacity"
                              onChange={value => {
                                let data = [...inputFields];
                                data[index]['capacity'] = value;
                                setInputFields(data);
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col md={12} xs={24}>
                          <Form.Item
                            name={[index, 'platform']}
                            label="Platform"
                            rules={[
                              {
                                required: true,
                                message: "'platform' is required",
                              },
                            ]}
                          >
                            <Radio.Group
                              onChange={event => {
                                handleFormChange(
                                  index,
                                  'platform',
                                  event.target.value,
                                );
                              }}
                            >
                              <Radio value="Online">Online</Radio>
                              <Radio value="On Site">On Site</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>

                      {inputFields[index]['platform'] === 'On Site' && (
                        <>
                          <Row gutter={[8, 8]}>
                            <Col xs={24}>
                              <Form.Item
                                name={[index, 'location']}
                                label="Location"
                                rules={[
                                  {
                                    required: true,
                                    message: "'location' is required",
                                  },
                                ]}
                              >
                                <Input
                                  name="location"
                                  onChange={event =>
                                    handleFormChange(
                                      index,
                                      'location',
                                      event.target.value,
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={[8, 8]}>
                            <Col xs={24}>
                              <Form.Item
                                name={[index, 'location_details']}
                                label="Location map URL"
                                rules={[
                                  {
                                    required: true,
                                    message: "'map URL' is required",
                                  },
                                  {
                                    type: 'url',
                                    message: 'Enter a valid url.',
                                  },
                                ]}
                              >
                                <Input
                                  name="location_details"
                                  onChange={event =>
                                    handleFormChange(
                                      index,
                                      'location_details',
                                      event.target.value,
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}

                      {inputFields[index]['platform'] === 'Online' && (
                        <>
                          <Row gutter={[8, 8]}>
                            <Col xs={24}>
                              <Form.Item
                                name={[index, 'location']}
                                label="Meeting Link"
                                rules={[
                                  {
                                    required: true,
                                    message: "'meeting link' is required",
                                  },
                                  {
                                    type: 'url',
                                    message: 'Enter a valid url.',
                                  },
                                ]}
                              >
                                <Input
                                  name="location"
                                  onChange={event =>
                                    handleFormChange(
                                      index,
                                      'location',
                                      event.target.value,
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row gutter={[8, 8]}>
                            <Col xs={24}>
                              <Form.Item
                                name={[index, 'location_details']}
                                label="Meeting Details (ID, passcode, etc.)"
                                rules={[
                                  {
                                    required: true,
                                    message: "'meeting details' is required",
                                  },
                                ]}
                              >
                                <Input
                                  name="location_details"
                                  onChange={event =>
                                    handleFormChange(
                                      index,
                                      'location_details',
                                      event.target.value,
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}

                      {fields.length > 1 && (
                        <>
                          <Row gutter={[8, 8]}>
                            <Col xs={24}>
                              <Button
                                onClick={() => {
                                  removeFields(index);
                                  remove(field.name);
                                }}
                              >
                                Remove
                              </Button>
                            </Col>
                          </Row>

                          <Row gutter={[8, 8]}>
                            <Col xs={24}>
                              <Divider
                                style={{ borderTop: '3px solid #000' }}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  ))}

                  <Row gutter={[8, 8]}>
                    <Col>
                      <Form.Item>
                        <Button
                          onClick={() => {
                            addFields();
                            add();
                          }}
                        >
                          Add More
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item>
                        <Button htmlType="submit">Submit</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            }}
          </Form.List>
        </Form>
      </Card>
    </NewSlotFormStyles>
  );
});
