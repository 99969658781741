import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client/link/http';
import Cookies from 'js-cookie';
import { useMemo } from 'react';

let apolloClient;

const getAuthLink = () => {
  return setContext((_, { headers }) => {
    const token = Cookies.get('accessToken');

    const language = Cookies.get('NEXT_LOCALE') || 'en';

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'Accept-Language': language,
      },
    };
  });
};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_DOMAIN_GRAPHQL,
});

function createApolloClient() {
  const authLink = getAuthLink();

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',

    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo() {
  const _apolloClient = createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
