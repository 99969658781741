/**
 *
 * Asynchronously loads the component for UsherProfile
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UsherProfile = lazyLoad(
  () => import('./index'),
  module => module.UsherProfile,
);
