/**
 *
 * Asynchronously loads the component for Ushers
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Ushers = lazyLoad(
  () => import('./index'),
  module => module.Ushers,
);
