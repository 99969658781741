import instance from '../instance';
import { handleError } from './errors';

import moment from 'moment';

export const EditInitiativeUsher =
  (usherID, initiativeID, data) => async () => {
    try {
      await instance.put(
        `initiatives/${initiativeID}/ushers/${usherID}/`,
        data,
      );
    } catch (error) {
      return handleError(error);
    }
  };

export const EditAttendanceHistory = (recordID, data) => async () => {
  try {
    await instance.put(`/attendance-history/${recordID}/`, data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const FetchAttendanceHistoryList = async (
  initiativeID,
  searchParams,
) => {
  try {
    const response = await instance.get(
      `/initiative/${initiativeID}/attendance/`,
      {
        params: searchParams,
      },
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const ExportAllData = searchParams => async () => {
  try {
    const response = await instance.get('/initiative-ushers/export/all/', {
      params: { ...searchParams, page_size: 1000 },
      responseType: 'blob',
    });
    const fileName = 'Ushers List ' + moment().format('DD-MM-YYYY') + '.xlsx';
    const headerLine = `attachment;filename="${fileName}"`;
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      headerLine.substring(startFileNameIndex, endFileNameIndex),
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};
