import { gql } from '@apollo/client';

export const GET_GOVERNORATES_LIST = gql`
  query GetGovernoratesList {
    governorates {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

export const GET_AREAS_LIST = gql`
  query GetAreasList {
    areas {
      edges {
        node {
          id
          pk
          name
          governorate {
            pk
            name
          }
        }
      }
    }
  }
`;

export const GET_INITIATIVES_LIST = gql`
  query GetInitiativesList {
    initiatives {
      edges {
        node {
          id
          pk
          englishName
          arabicName
          englishDescription
          arabicDescription
          startingDate
          endingDate
          image
          registrationClosed
          ageFrom
          ageTo
        }
      }
    }
  }
`;

export const GET_INITIATIVE_DETAILS = gql`
  query GetInitiativeDetails($id: ID!) {
    initiative(id: $id) {
      id
      pk
      englishName
      arabicName
      englishDescription
      arabicDescription
      startingDate
      endingDate
      image
      contractEn
      contractAr
      registrationClosed
      ageFrom
      ageTo
      location {
        id
        pk
        nameAr
        nameEn
      }
      tag {
        id
        pk
        title
      }
      startingTime
      endingTime
      steps {
        id
        step {
          pk
          title
          description
          shouldBook
        }
        order
      }
    }
  }
`;

export const GET_STEPS_LIST = gql`
  query GetStepsList {
    steps {
      edges {
        node {
          id
          pk
          title
          description
          shouldBook
          shouldAddResults
        }
      }
    }
  }
`;

export const GET_TAGS_LIST = gql`
  query GetTagsList {
    tags {
      edges {
        node {
          pk
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_LEVELS_LIST = gql`
  query GetLevelsList {
    levels {
      edges {
        node {
          pk
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_GUIDE_STATUS_LIST = gql`
  query GetGuideStatusList {
    guideStatus {
      edges {
        node {
          pk
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_INITIATIVE_SLOTS_LIST = gql`
  query GetInitiativeSlotsList {
    timeSlots {
      edges {
        node {
          pk
          id
          date
          startingTime
          endingTime
          capacity
          platform
          location
          locationDetails
          initiativeStep {
            step {
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATIONS_LIST = gql`
  query GetLocationsList($isMainLocation: Boolean, $isActive: Boolean) {
    locations(isMainLocation: $isMainLocation, isActive: $isActive) {
      edges {
        node {
          pk
          id
          nameEn
          nameAr
          isActive
          mapUrl
          governorate
          area
          mainLocationAddress
          subLocations {
            id
            nameAr
            nameEn
            governorate
            area
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_DETAILS = gql`
  query GetLocationDetails($initiativeId: Int) {
    location(initiativeId: $initiativeId) {
      pk
      id
      nameAr
      nameEn
      isActive
      governorate
      subLocations {
        id
        nameAr
        nameEn
        governorate
        area
      }
      area
      mapUrl
    }
  }
`;

export const GET_INITIATIVE_SHIFTS_LIST = gql`
  query GetInitiativeShiftsList($initiativeId: Int) {
    shifts(initiativeId: $initiativeId) {
      edges {
        node {
          id
          pk
          startingDate
          endingDate
          startingTime
          endingTime
        }
      }
    }
  }
`;

export const GET_INITIATIVE_ROLES_LIST = gql`
  query GetInitiativeRolesList($initiativeId: Int) {
    roles(initiativeId: $initiativeId) {
      edges {
        node {
          id
          pk
          title
        }
      }
    }
  }
`;

export const GET_INITIATIVE_USHER_DETAILS = gql`
  query GetInitiativeUsherDetails(
    $userId: ID
    $initiativeId: ID
    $initiativeUsherId: ID
  ) {
    initiativeUshers(
      userId: $userId
      initiativeId: $initiativeId
      initiativeUsherId: $initiativeUsherId
    ) {
      edges {
        node {
          pk
          id
          status
          generalStatus
          specificStatus
          assignedLocation {
            nameEn
          }
          assignedRole {
            title
          }
          assignedShift {
            startingDate
            endingDate
            startingTime
            endingTime
          }
          initiative {
            englishName
            startingDate
            endingDate
          }
          usher {
            englishFullName
          }
        }
      }
    }
  }
`;

export const GET_ATTENDANCE_HISTORY_LIST = gql`
  query GetAttendanceHistoryList($initiativeUsherId: Decimal!) {
    attendanceHistory(initiativeUsherId: $initiativeUsherId) {
      edges {
        node {
          pk
          id
          date
          checkInTime
          checkOutTime
          status
          comments
        }
      }
    }
  }
`;

export const GET_ALL_ATTENDANCE_HISTORY_LIST = gql`
  query GetAllAttendanceHistoryList($initiative: Float) {
    attendanceHistory(initiative: $initiative) {
      edges {
        node {
          pk
          id
          status
          checkInTime
          checkOutTime
          date
          comments
          initiativeUsher {
            initiative {
              englishName
            }
            usher {
              usherId
              englishFullName
            }
            assignedShift {
              startingDate
              endingDate
              startingTime
              endingTime
            }
            assignedRole {
              title
            }
            assignedLocation {
              nameEn
            }
          }
        }
      }
    }
  }
`;
