/**
 *
 * Asynchronously loads the component for InitiativeManagement
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InitiativeManagement = lazyLoad(
  () => import('./index'),
  module => module.InitiativeManagement,
);
