import { Badge } from 'antd';

import * as XLSX from 'xlsx';

// Function to convert time string to Date object
function parseTime(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0); // Ignore seconds
  return date;
}

// Function to check if time is within 10 minutes range
function isWithin10Minutes(time1, time2) {
  const diffMilliseconds = Math.abs(time1 - time2);
  const diffMinutes = diffMilliseconds / (1000 * 60); // Convert milliseconds to minutes
  return diffMinutes <= 10;
}

export const cellBadge = (attendanceRecord, assignedShift) => {
  // No attendance record for the day.
  if (!attendanceRecord) return <Badge color="red" />;

  let checkInTime = attendanceRecord.checkInTime;
  let checkOutTime = attendanceRecord.checkOutTime;

  // Did not check in or out.
  if (!checkInTime || !checkOutTime) return <Badge color="orange" />;

  if (assignedShift) {
    checkInTime = parseTime(checkInTime);
    checkOutTime = parseTime(checkOutTime);

    const shiftStartingTime = parseTime(assignedShift.startingTime);
    const shiftEndingTime = parseTime(assignedShift.endingTime);

    if (
      isWithin10Minutes(checkInTime, shiftStartingTime) &&
      isWithin10Minutes(checkOutTime, shiftEndingTime)
    )
      return <Badge color="green" />;
    else if (checkInTime > shiftStartingTime || checkOutTime < shiftEndingTime)
      return <Badge color="yellow" />;
    else if (checkInTime < shiftStartingTime || checkOutTime > shiftEndingTime)
      return <Badge color="geekblue" />;
  }

  return 'N/A';
};

export const cellText = (attendanceRecord, assignedShift) => {
  // No attendance record for the day.
  if (!attendanceRecord) return 'No attendance record';

  let checkInTime = attendanceRecord.checkInTime;
  let checkOutTime = attendanceRecord.checkOutTime;

  // Did not check in or out.
  if (!checkInTime || !checkOutTime) return 'Did not check in or out';

  if (assignedShift) {
    checkInTime = parseTime(checkInTime);
    checkOutTime = parseTime(checkOutTime);

    const shiftStartingTime = parseTime(assignedShift.startingTime);
    const shiftEndingTime = parseTime(assignedShift.endingTime);

    if (
      isWithin10Minutes(checkInTime, shiftStartingTime) &&
      isWithin10Minutes(checkOutTime, shiftEndingTime)
    )
      return 'Normal Hours';
    else if (checkInTime > shiftStartingTime || checkOutTime < shiftEndingTime)
      return 'Missing Hours';
    else if (checkInTime < shiftStartingTime || checkOutTime > shiftEndingTime)
      return 'Extra Hours';
  }

  return 'N/A';
};

export const exportToExcel = (dataSource, columns) => {
  // Convert dataSource to an array of objects with keys corresponding to column titles
  const dataForSheet = dataSource.map(item => {
    const rowData = {};
    columns.forEach(col => {
      console.log('col: ', col);
      console.log('item: ', item);

      console.log('col.dataIndex', !!col.dataIndex, col.dataIndex);

      rowData['Usher ID'] = item['usherID'];

      rowData['Full Name'] = item['usherName'];

      rowData['Assigned Shift'] =
        item['assignedShift']['startingTime'] +
        ' - ' +
        item['assignedShift']['endingTime'];

      if (col.dataIndex) {
        const record = item.attendanceRecords.find(
          obj => Object.keys(obj)[0] === col.dataIndex,
        );
        rowData[col.title] = cellText(record[col.title], item.assignedShift);
      }
    });
    return rowData;
  });

  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(dataForSheet);

  // Create a workbook and add the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Export the workbook as an Excel file
  XLSX.writeFile(wb, 'table_data.xlsx');
};
