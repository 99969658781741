import styled from 'styled-components';

const ProfilePageStyles = styled.div`
  overflow-x: hidden;
  padding: 24px;

  .ant-card {
    background-color: #adb5bd1c;
    border-color: #adb5bd1c;
    border-width: 2px;
  }

  .ant-card-body {
    background-color: #fff;
  }

  img {
    width: 165px;
    height: 165px;
    border-radius: 50%;
  }

  label {
    font-size: 14px;
  }

  .form-control {
    font-size: 14px;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #ced4da;
    border-radius: 0;
    padding-left: 5px;
  }

  .react-tel-input .form-control {
    font-size: 14px;
    color: #000000d9;
    border-radius: 2px;
    width: 100%;
    height: 30px;
  }
`;

export default ProfilePageStyles;
