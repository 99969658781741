/**
 *
 * Navbar
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Layout, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import LeftMenu from './LeftMenu';
import RightMenu from './RightMenu';

import NavbarStyles from './styles';

interface Props {}

export const Navbar = memo((props: Props) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(!open);
  };

  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  const { pathname: location } = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <NavbarStyles>
      <nav className="navbar">
        <Layout>
          <Layout.Header className="nav-header">
            <Link to="/" className="logo">
              <img
                className="brand-font"
                src="/logo.png"
                alt="LSV Logo"
                width="60"
              />
            </Link>

            <LeftMenu mode="horizontal" />

            <RightMenu mode="horizontal" />

            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>

            <Drawer
              title={
                <img
                  className="brand-font"
                  src="/logo.png"
                  alt="LSV Logo"
                  width="60"
                />
              }
              placement="right"
              closable={true}
              onClose={showDrawer}
              open={open}
              style={{ zIndex: 99999 }}
            >
              <LeftMenu mode="inline" />
              <RightMenu mode="inline" />
            </Drawer>
          </Layout.Header>
        </Layout>
      </nav>
    </NavbarStyles>
  );
});
