import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { UsherProfile } from './components/UsherProfile/Loadable';
import { Dashboard as AttendanceHistoryDashboard } from './pages/AttendanceHistory/Dashboard';
import { AttendanceHistory } from './pages/AttendanceHistory/Loadable';
import { LoginForm } from './pages/Auth/Login/Loadable';
import { HomePage } from './pages/HomePage/Loadable';
import { InitiativeDetails } from './pages/InitiativeDetails/Loadable';
import { InitiativeManagement } from './pages/InitiativeManagement/Loadable';
import { Levels } from './pages/Levels/Loadable';
import { Locations } from './pages/Locations/Loadable';
import { Steps } from './pages/Steps/Loadable';
import { Tags } from './pages/Tags/Loadable';
import { TimeSlots } from './pages/TimeSlots/Loadable';
import { NewSlotForm } from './pages/TimeSlots/NewSlotForm';
import { Ushers } from './pages/Ushers/Loadable';

import { useAuth } from './hooks/useAuth';

import InitiativeEdit from './pages/InitiativeEdit';
import NewInitiative from './pages/NewInitiative';
import ProfilePage from './pages/ProfilePage';

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (!isAuthenticated) return children;
  else navigate('/');
};

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) return children;
  else navigate('/login');
};

export function AppRoutes() {
  return (
    <div className="m-2 m-md-5">
      <Routes>
        <Route
          path="/ushers/:usherID/profile/"
          element={
            <PrivateRoute>
              <UsherProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile/"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/attendance/"
          element={
            <PrivateRoute>
              <AttendanceHistoryDashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/attendance/:initiativeUsherID/history/"
          element={
            <PrivateRoute>
              <AttendanceHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="/locations"
          element={
            <PrivateRoute>
              <Locations />
            </PrivateRoute>
          }
        />
        <Route
          path="/initiatives/:initiativeID/slots/"
          element={
            <PrivateRoute>
              <TimeSlots />
            </PrivateRoute>
          }
        />
        <Route
          path="/initiatives/:initiativeID/slots/add/"
          element={
            <PrivateRoute>
              <NewSlotForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/tags/"
          element={
            <PrivateRoute>
              <Tags />
            </PrivateRoute>
          }
        />
        <Route
          path="/levels/"
          element={
            <PrivateRoute>
              <Levels />
            </PrivateRoute>
          }
        />
        <Route
          path="/initiatives-management/"
          element={
            <PrivateRoute>
              <InitiativeManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/ushers/"
          element={
            <PrivateRoute>
              <Ushers />
            </PrivateRoute>
          }
        />
        <Route
          path="/steps/"
          element={
            <PrivateRoute>
              <Steps />
            </PrivateRoute>
          }
        />
        <Route
          path="/initiatives/add/"
          element={
            <PrivateRoute>
              <NewInitiative />
            </PrivateRoute>
          }
        />
        <Route
          path="/initiatives/:initiativeID/edit/"
          element={
            <PrivateRoute>
              <InitiativeEdit />
            </PrivateRoute>
          }
        />

        <Route
          path="/initiatives/:initiativeID/"
          element={<InitiativeDetails />}
        />
        <Route
          path="/login/"
          element={
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          }
        />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}
