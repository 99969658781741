import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Menu } from 'antd';

import { useAuth } from '../../hooks/useAuth';

const LeftMenu = ({ mode }) => {
  const { t } = useTranslation();

  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const defaultSelectedKeys =
    location.pathname === '/profile'
      ? ['profile']
      : location.pathname === '/initiatives/add'
      ? ['new-initiative']
      : location.pathname === '/initiatives'
      ? ['initiatives']
      : [];

  const menuItems = isAuthenticated
    ? [
        {
          label: t('navbar.initiativesTab'),
          key: 'initiatives',
          onClick: () => navigate('/'),
        },
        {
          label: t('navbar.newInitiativeTab'),
          key: 'new-initiative',
          onClick: () => navigate('/initiatives/add'),
        },
        {
          label: t('navbar.listsTab'),
          key: 'lists',
          children: [
            {
              label: t('navbar.ushersListTab'),
              key: 'ushers-list',
              onClick: () => navigate('/ushers'),
            },
            {
              label: 'Initiatives Management List',
              key: 'initiatives-management-list',
              onClick: () => navigate('/initiatives-management'),
            },
            {
              label: t('navbar.stepsListTab'),
              key: 'steps-list',
              onClick: () => navigate('/steps'),
            },
            {
              label: t('navbar.levelsListTab'),
              key: 'levels-list',
              onClick: () => navigate('/levels'),
            },
            {
              label: 'Tags List',
              key: 'tags-list',
              onClick: () => navigate('/tags'),
            },
            {
              label: 'Locations List',
              key: 'locations-list',
              onClick: () => navigate('/locations'),
            },
            {
              label: 'Attendance History',
              key: 'attendance-history',
              onClick: () => navigate('/attendance/'),
            },
          ],
        },
        {
          label: t('navbar.profileTab'),
          key: 'profile',
          onClick: () => navigate('/profile'),
        },
      ]
    : [
        {
          label: t('common.homePage'),
          key: 'home',
          onClick: () => navigate('/'),
        },
      ];

  return (
    <div className="leftMenu">
      <Menu
        mode={mode}
        defaultSelectedKeys={defaultSelectedKeys}
        items={menuItems}
        style={{ width: menuItems.length === 0 ? '0px' : '100%' }}
      />
    </div>
  );
};

export default LeftMenu;
