import React from 'react';

import moment from 'moment';

import { Select, Row, Col, Typography, DatePicker } from 'antd';

import { useQuery } from '@apollo/client';
import { GET_INITIATIVES_LIST } from '../../graphql/queries';

import { Loading } from '../Loading';

const { Option } = Select;

const { Text } = Typography;

const FiltersDrawer = ({
  setInitiative,
  setInitiativeLabel,
  setSearchParams,
  searchParams,
}) => {
  const { loading: initiativesListLoading, data: initiativesListData } =
    useQuery(GET_INITIATIVES_LIST);

  if (initiativesListLoading) return <Loading />;

  return (
    <>
      <Row className="my-2">
        <Col xs={24}>
          <Text className="fw-bold">Initiative:</Text>
          <Select
            onChange={(value, option) => {
              setInitiative(value);
              setInitiativeLabel(option.children.join(' '));
            }}
            style={{ width: '100%' }}
            defaultValue=""
            className="mt-2"
          >
            <Option key="" value="">
              -----
            </Option>
            ,
            {initiativesListData?.initiatives?.edges.map(obj => (
              <Option key={obj.node.pk} value={obj.node.pk}>
                {obj.node.englishName} -&nbsp;
                {moment(obj.node.startingDate).format('DD/MM/YYYY')}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row className="my-2">
        <Col xs={24}>
          <Text className="fw-bold">Year:</Text>
          <DatePicker
            picker="year"
            style={{ width: '100%' }}
            className="mt-2"
            onChange={value => {
              if (value)
                setSearchParams({ ...searchParams, year: value.year() });
              else setSearchParams({ ...searchParams, year: '' });
            }}
          />
        </Col>
      </Row>

      <Row className="my-2">
        <Col xs={24}>
          <Text className="fw-bold">Month:</Text>
          <DatePicker
            picker="month"
            format="MMMM"
            style={{ width: '100%' }}
            className="mt-2"
            onChange={value => {
              if (value)
                setSearchParams({ ...searchParams, month: value.month() + 1 });
              else setSearchParams({ ...searchParams, month: '' });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default FiltersDrawer;
