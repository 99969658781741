import React, { useState, useEffect } from 'react';

import { Drawer } from 'antd';

import FiltersDrawer from './FiltersDrawer';
import AttendanceTable from './AttendanceTable';

import { FetchAttendanceHistoryList } from '../../redux/actions/InitiativeUsher';

const AttendanceHistoryDashboard = () => {
  const [initiative, setInitiative] = useState(null);
  const [initiativeLabel, setInitiativeLabel] = useState(null);

  const [searchParams, setSearchParams] = useState({
    month: '',
    year: '',
  });

  const [openDrawer, setOpenDrawer] = useState(false);

  const [attendanceData, setAttendanceData] = useState(null);
  const [attendanceDataLoading, setAttendanceDataLoading] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    async function fetchData() {
      setAttendanceDataLoading(true);
      const response = await FetchAttendanceHistoryList(
        initiative,
        searchParams,
      );
      setAttendanceData(response);
      setAttendanceDataLoading(false);
    }
    if (initiative) fetchData();
  }, [initiative, searchParams]);

  return (
    <>
      <Drawer
        title="Filter Attendance History List"
        onClose={closeDrawer}
        open={openDrawer}
        style={{ paddingTop: '75px' }} // Adjust top padding to avoid overlaying the fixed navbar
      >
        <FiltersDrawer
          {...{
            setInitiative,
            setInitiativeLabel,
            searchParams,
            setSearchParams,
          }}
        />
      </Drawer>

      <AttendanceTable
        {...{
          attendanceData,
          attendanceDataLoading,
          initiative,
          initiativeLabel,
          setInitiative,
          searchParams,
          setSearchParams,
          showDrawer,
        }}
      />
    </>
  );
};

export default AttendanceHistoryDashboard;
