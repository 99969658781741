import instance from '../instance';
import { handleError } from './errors';

export const NewInitiative = initiativeData => async () => {
  try {
    const formData = new FormData();
    Object.keys(initiativeData).forEach(key => {
      formData.append(key, initiativeData[key]);
    });
    const response = await instance.post('/initiatives/add/', formData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const EditInitiative = (initiativeData, initiativeID) => async () => {
  try {
    const formData = new FormData();
    Object.keys(initiativeData).forEach(key => {
      formData.append(key, initiativeData[key]);
    });
    // remove image if it didn't get updated.
    if (typeof formData.get('image') === 'string') formData.delete('image');
    if (typeof formData.get('contract_en') === 'string')
      formData.delete('contract_en');
    if (typeof formData.get('contract_ar') === 'string')
      formData.delete('contract_ar');

    const response = await instance.put(
      `/initiatives/${initiativeID}/update/`,
      formData,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const NewDoc = (initiativeID, DocsList) => async () => {
  try {
    await Promise.all(
      DocsList.map(async data => {
        const docData = { ...data, initiative: initiativeID };
        const formData = new FormData();
        Object.keys(docData).forEach(key => {
          formData.append(key, docData[key]);
        });
        await instance.post(`initiatives/${initiativeID}/doc/add/`, formData);
      }),
    );
  } catch (error) {
    return handleError(error);
  }
};

export const NewRole = (initiativeID, RolesList) => async () => {
  try {
    await Promise.all(
      RolesList.map(async data => {
        const roleData = { ...data, initiative: initiativeID };
        const formData = new FormData();
        Object.keys(roleData).forEach(key => {
          formData.append(key, roleData[key]);
        });
        await instance.post(`initiatives/${initiativeID}/role/add/`, formData);
      }),
    );
  } catch (error) {
    return handleError(error);
  }
};

export const NewShift = (initiativeID, ShiftsList) => async () => {
  try {
    await Promise.all(
      ShiftsList.map(async data => {
        const shiftData = { ...data, initiative: initiativeID };
        const formData = new FormData();
        Object.keys(shiftData).forEach(key => {
          formData.append(key, shiftData[key]);
        });
        await instance.post(`initiatives/${initiativeID}/shift/add/`, formData);
      }),
    );
  } catch (error) {
    return handleError(error);
  }
};

export const NewLocation = data => async () => {
  try {
    const response = await instance.post('/locations/add/', data);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const NewSubLocation = (subLocationsList, extraData) => async () => {
  try {
    await Promise.all(
      subLocationsList.map(async data => {
        await instance.post('/locations/add/', {
          ...data,
          ...extraData,
        });
      }),
    );
  } catch (error) {
    return handleError(error);
  }
};

export const NewStep = data => async () => {
  try {
    await instance.post('steps/add/', data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const EditStep = (stepID, data) => async () => {
  try {
    await instance.put(`steps/${stepID}/`, data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const InitiativeSteps = (initiativeID, data) => async () => {
  try {
    const response = await instance.put(
      `initiatives/${initiativeID}/steps/`,
      data,
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const NewLevel = data => async () => {
  try {
    await instance.post('/levels/add/', data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const EditLevel = (levelID, data) => async () => {
  try {
    await instance.put(`/levels/${levelID}/`, data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const NewTag = data => async () => {
  try {
    await instance.post('/tags/add/', data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const EditTag = (tagID, data) => async () => {
  try {
    await instance.put(`/tags/${tagID}/`, data);
    return 'Success';
  } catch (error) {
    return handleError(error);
  }
};

export const NewTimeSlot = TimeSlotsList => async () => {
  try {
    await Promise.all(
      TimeSlotsList.map(async data => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
          formData.append(key, data[key]);
        });
        await instance.post('timeslots/add/', formData);
      }),
    );
  } catch (error) {
    return handleError(error);
  }
};
