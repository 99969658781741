/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';

import { GlobalStyle } from 'styles/global-styles';

import { useTranslation } from 'react-i18next';

import { ConfigProvider } from 'antd';
import { initializeApollo } from './apollo';

import { AppRoutes } from './AppRoutes';
import { Navbar } from './components/Navbar';
import { AuthProvider } from './hooks/useAuth';

export function App() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  React.useEffect(() => {
    if (currentLanguage === 'ar') {
      document.dir = 'rtl';
    } else {
      document.dir = 'ltr';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider
      direction={currentLanguage === 'ar' ? 'rtl' : 'ltr'}
      theme={{
        token: {
          colorPrimary: '#25B24B',
          fontFamily: currentLanguage === 'ar' ? 'Tajawal' : 'Poppins',
        },
      }}
    >
      <BrowserRouter>
        <Helmet
          titleTemplate="%s - LOYAC Sustainable Ventures"
          defaultTitle="LOYAC Sustainable Ventures"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name="description" content="LOYAC Sustainable Ventures" />
        </Helmet>

        <ApolloProvider client={initializeApollo()}>
          <AuthProvider>
            <Navbar />

            <AppRoutes />
          </AuthProvider>
        </ApolloProvider>
        <GlobalStyle />
      </BrowserRouter>
    </ConfigProvider>
  );
}
