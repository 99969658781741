/**
 *
 * Asynchronously loads the component for NewInitiative
 *
 */

import { lazyLoad } from 'utils/loadable';

export const NewInitiativeForm = lazyLoad(
  () => import('./index'),
  module => module.NewInitiativeForm,
);
