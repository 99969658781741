import instance from '../instance';
import { handleError } from './errors';

export const Login = data => {
  return async () => {
    try {
      const response = await instance.post(`login/?is_lsv_staff=${true}`, data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
};

export const GenerateNewTokens = refreshToken => {
  return async () => {
    try {
      const response = await instance.post('token/refresh/', {
        refresh: refreshToken,
      });
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  };
};

export const BlacklistToken = refreshToken => {
  return async () => {
    try {
      await instance.post('token/blacklist/', {
        refresh: refreshToken,
      });
    } catch (error) {
      return handleError(error);
    }
  };
};

export const Signup = data => async dispatch => {
  try {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    await instance.post('ushers/apply/', formData);
    return dispatch(Login({ email: data.email, password: data.password }));
  } catch (error) {
    return handleError(error);
  }
};

export const FetchProfile = userID => async dispatch => {
  try {
    const response = await instance.get(`/staff/${userID}/`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
